import React from 'react';
import loadable from '@loadable/component';

const ResourcesListing = loadable(() =>
  import('./ResourcesListing/ResourcesListing')
);

const ListingGenerator = ({ component, allResources }) => {
  const { variant } = component;
  switch (variant) {
    case 'resources-listing':
      return <ResourcesListing allResources={allResources} />;
    default:
      return null;
  }
};

export default ListingGenerator;
